<template>
	<div class="page">
		<div class="page_body">
			<iframe :src="url"></iframe>
		</div>
	</div>
</template>

<script>
import Vue from 'vue'
import StatusBar from '@/components/StatusBar'
import NavBar from '@/components/NavBar'

export default {
  name: 'Html',
  components: {
    StatusBar,
    NavBar
  },
  data () {
    return {

    }
  },
  computed: {
    url: function () {
      return (this.$route.query.url != undefined) ? this.$route.query.url : 'https://www.weibo.com/'
    },
    leftLink: function () {
      return (this.$route.query.leftLink != undefined) ? this.$route.query.leftLink : '/reg'
    },
    title: function () {
      return (this.$route.query.subject != undefined) ? this.$route.query.subject : '淘街坊'
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.page{background:#f8f8f8;}
.page_body{margin:0;}
iframe{border:0;padding:0;margin:0;width:100%;height:100%;height:calc(100vh);}
</style>
